
import React from "react";
import { Container, Box, List, ListItem } from '@mui/material';


function Contact() {
  return (
  <> 
    
    <Container id="contact" className="contact">
  <Box className="container" data-aos="fade-up">
    <Box className="section-title">
      <h2>Contact</h2>
      <h3>
        <span>Contact Us</span>
      </h3>
      
    </Box>
    <Box className="row" data-aos="fade-up" data-aos-delay={100}>
      <Box className="col-lg-6">
        <Box className="info-box mb-4">
          <i className="bx bx-map" />
          <h3>Our Address</h3>
          <p>
              General Wairungi Street <br />
              Eastleigh, BBS
              <br />
              Nrb, Kenya <br /></p>
        </Box>
      </Box>
      <Box className="col-lg-3 col-md-6">
        <Box className="info-box  mb-4">
          <i className="bx bx-envelope" />
          <h3>Email Us</h3>
          <a href="mailto:info@aishadrycleaner.com">
                    info@aishadrycleaner.com
                  </a>
        </Box>
      </Box>
      <Box className="col-lg-3 col-md-6">
        <Box className="info-box  mb-4">
          <i className="bx bx-phone-call" />
          <h3>Call Us</h3>
          <p>+254 112 222 333</p>
        </Box>
      </Box>
    </Box>
    <Box className="row" data-aos="fade-up" data-aos-delay={100}>
      <Box className="col-lg-12 ">
        
      </Box>
      
    </Box>
  </Box>
</Container>
<Container id="faq" className="faq section-bg">
  <Box className="container" data-aos="fade-up">
    <Box className="section-title">
      <h2>F.A.Q</h2>
      <h3>
        Frequently Asked <span>Questions</span>
      </h3>
    
    </Box>
    <Box className="row justify-content-center">
      <Box className="col-xl-10">
        <List className="faq-list">
          <ListItem>
            <Box
              data-bs-toggle="collapse"
              className="collapsed question"
              href="#faq1"
            >
             What is dry cleaning?{" "}
              <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </Box>
            <Box id="faq1" className="collapse" data-bs-parent=".faq-list">
              <p>
              Dry cleaning is a cleaning process that uses a solvent, such as perchloroethylene or hydrocarbon, to remove dirt and stains from fabrics and textiles without using water. It is an effective method for cleaning delicate and special garments that cannot be washed in a regular washing machine.
              </p>
            </Box>
          </ListItem>
          <ListItem>
            <Box
              data-bs-toggle="collapse"
              href="#faq2"
              className="collapsed question"
            >
              What types of garments can be dry cleaned? <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </Box>
            <Box id="faq2" className="collapse" data-bs-parent=".faq-list">
              <p>
              Dry cleaning is suitable for a wide range of garments, including suits, dresses, blouses, shirts, ties, coats, and more. It can also be used to clean household items like curtains and drapes.
              </p>
            </Box>
          </ListItem>
          <ListItem>
            <Box
              data-bs-toggle="collapse"
              href="#faq3"
              className="collapsed question"
            >
             Is dry cleaning safe for all fabrics?
             <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </Box>
            <Box id="faq3" className="collapse" data-bs-parent=".faq-ListItemst">
              <p>
              Dry cleaning is generally safe for most fabrics, including silk, wool, cashmere, and delicate fabrics. However, it may not be suitable for fabrics that are prone to shrinking or losing color when exposed to solvents. It's essential to check the care labels on your garments or consult with a professional dry cleaner if you have any concerns.
              </p>
            </Box>
          </ListItem>
          <ListItem>
            <Box
              data-bs-toggle="collapse"
              href="#faq4"
              className="collapsed question"
            >
              How often should I dry clean my clothes?{" "}
              <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </Box>
            <Box id="faq4" className="collapse" data-bs-parent=".faq-list">
              <p>
              The frequency of dry cleaning depends on how often you wear the garment and the type of fabric. For most everyday clothing, dry cleaning is not required after every wear. Instead, you can follow the care instructions on the label and have the garments dry cleaned when they are visibly soiled or stained.
              </p>
            </Box>
          </ListItem>
          <ListItem>
            <Box
              data-bs-toggle="collapse"
              href="#faq5"
              className="collapsed question"
            >
              Can stains be completely removed through dry cleaning?
               <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </Box>
            <Box id="faq5" className="collapse" data-bs-parent=".faq-list">
              <p>
              Dry cleaning can effectively remove many types of stains, but it may not be able to remove all stains entirely. The success of stain removal depends on the type of stain, the fabric, and how long the stain has been present. It's best to inform the dry cleaner about any specific stains on your garments for appropriate treatment.
              </p>
            </Box>
          </ListItem>
          <ListItem>
           
            
          </ListItem>
        </List>
      </Box>
    </Box>
  </Box>
</Container>
</>
  )
}

export default Contact;


