import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, IconButton, List, ListItem, ListItemIcon, ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';
import theme from '../theme';
import { Person } from '@mui/icons-material';


const TopBar = styled(AppBar)({
 backgroundColor: theme.secondary,
 boxShadow: 'none',
 marginBottom: '50px'
});


const LogoImg = styled('img')({
  height: '80px', // Adjust the height as per your logo size
  width: 'auto',
  marginRight: '16px', // Add some margin to create spacing between logo and buttons
  marginTop: '20px'
});

// Styled custom button component
const CustomButton = styled(Button)({
  margin: '8px',
  color: theme.palette.secondary.macho,
  fontSize: 18
});

const ListButton = styled(Button)({
    color: theme.palette.secondary.macho,
  });
  
  const Headers = () => {
    const isXL = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const [menuOpen, setMenuOpen] = useState(false);
  
    useEffect(() => {
      const handleResize = () => {
        setMenuOpen(false); // Close the menu on window resize
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    const handleMenuToggle = () => {
      setMenuOpen((prevMenuOpen) => !prevMenuOpen); // Toggle menu state
    };
  

    const handleLinkClick = () => {
      window.open('https://app.aishadrycleaner.com', '_blank');
    };
  
    return (
        <TopBar position="static">
          <Toolbar>
            <a href='/'>
            <LogoImg src="./logo/logo.svg" alt="Logo" /></a>
            
            <div style={{ flexGrow: 1 }} />
    
            {isXL ? (
              <>
                <CustomButton color="macho" component={Link} to="/"> Home</CustomButton>
                <CustomButton color="macho" component={Link} to="/catalogue"> Catalogue</CustomButton>
                <CustomButton color="macho" component={Link} to="/about"> About</CustomButton>
                <CustomButton color="macho" component={Link} to="/services"> Service</CustomButton>
                <CustomButton color="macho" component={Link} to="/contacts"> Contact</CustomButton>
                <IconButton edge="end" color="macho" aria-label="login" onClick={handleLinkClick}>
                <Person />
              </IconButton>
              </>
            ) : (
              <IconButton edge="end" color="macho" aria-label="menu" onClick={handleMenuToggle}>
                <MenuIcon />
              </IconButton>
            )}
          </Toolbar>
    
          {/* The list will only appear when the screen size is not xl and the menu is open */}
          {!isXL && menuOpen && (
            <List component="nav">
              <ListItem button>
                <ListButton color="macho" component={Link} to="/">Home</ListButton>
              </ListItem>
              <ListItem button>
                <ListButton color="macho"component={Link} to="/catalogue">Catalogue</ListButton>
              </ListItem>
              <ListItem button>
                <ListButton color="macho"component={Link} to="/about">About</ListButton>
              </ListItem>
              <ListItem button>
                <ListButton color="macho" component={Link} to="/services">Service</ListButton>
              </ListItem>
              <ListItem button>
                <ListButton color="macho" component={Link} to="/contacts">Contact</ListButton>
              </ListItem>
              <ListItemButton 
              color="macho" 
              onClick={handleLinkClick}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              </ListItemButton>
              
            </List>
          )}
        </TopBar>
      );
    };

export default Headers;
