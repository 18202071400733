import React, { useState, useEffect } from "react";
import { Box } from '@mui/material';

const CountItem = ({ iconClass, startValue, endValue, duration, label }) => {
  const [count, setCount] = useState(startValue);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < endValue) {
        setCount((prevCount) => prevCount + 10); // Increase count by 10 for faster speed
      }
    }, duration * 100);

    return () => clearInterval(interval);
  }, [count, duration, endValue]);

  return (
    <Box className="col-lg-3 col-md-6 mt-5 mt-lg-0">
      <div className="count-box">
        <i className={iconClass} />
        <span>{count}</span>
        <p>{label}</p>
      </div>
    </Box>
  );
};

function Counts() {
  return (
    <section id="counts" className="counts">
      <Box className="container" data-aos="fade-up">
        <Box className="row">
          <CountItem iconClass="bi bi-emoji-smile" startValue={1000} endValue={5000000} duration={0.2} label="Happy Clients" />
          <CountItem iconClass="bi bi-journal-richtext" startValue={0} endValue={7000000} duration={0.2} label="Succesfully Delivered" />
          <CountItem iconClass="bi bi-headset" startValue={0} endValue={140063} duration={0.1} label="Hours Of Operation" />
          <CountItem iconClass="bi bi-people" startValue={0} endValue={15} duration={1} label="Team at Your Service" />
        </Box>
      </Box>
    </section>
  );
}

export default Counts;
