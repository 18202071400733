import React, { useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import {
  Box,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import axios from 'axios';
import { useParams } from "react-router-dom";


// function ProductDetailsTable({ product }) {
//     return (
//       <TableContainer>
//         <Table size="small">
//           <TableHead>
//             <TableRow>
//               <TableCell>Attribute</TableCell>
//               <TableCell>Value</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             <TableRow>
//               <TableCell>Color</TableCell>
//               <TableCell>{product.color || ''}</TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell>Size</TableCell>
//               <TableCell>{product.size || ''}</TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell>Desc</TableCell>
//               <TableCell>{product.desc || ''}</TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell>Material</TableCell>
//               <TableCell>{product.material || ''}</TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell>Stain</TableCell>
//               <TableCell>{product.stain.join(', ') || ''}</TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell>Damages</TableCell>
//               <TableCell>{product.damages.join(', ') || ''}</TableCell>
//             </TableRow>
//             <TableRow>
//               <TableCell>Express</TableCell>
//               <TableCell> 
//               Ksh {product.express ? `${product.price * 0.35}` : `0 `}
//   {/* 
//               {product.express
//                   ? `${(product.price * 1.35).toFixed(2)}$ (${(35).toFixed(0)}% Express)`
//                   : '0'} */}
//           </TableCell>
//             </TableRow>
//           </TableBody>
//         </Table>
//       </TableContainer>
//     );
//   }
// const StyledBox = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(2),
//   textAlign: 'left',
//   boxShadow: 'none',
//   width: '100%'
// }));

function Qr() {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const [transition, setTransition] = useState(undefined);
  const [payments, setPayments] = useState(null);
  const [orders, setOrders] = useState(null);
  const { orderId } = useParams();


  const fetchOrders = () => {
    if (orderId) {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `/api/orders/qrcode/${orderId}`
          );
          setOrders(res.data.order);
          setPayments(res.data.payments);
        } catch (error) {
          setOpen(true);
          setTransition(transition);
          setError(error.response.data.error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  };

  useEffect(fetchOrders, [
    orderId,
    transition,
    setOpen,
    setIsLoading,
  ]);


// if (!orders || !orders.description) {
//     return (
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "center",
//           mt: "200px",
//           mb: "200px",
//         }}
//       >
//         <Typography variant="h3">Order Not Available</Typography>
//       </Box>
//     );
//   }

  // const Loading =()=>{
    if (isLoading) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            mt: "200px",
            mb: "200px",
          }}
        >
          <Typography variant="h3">loading...</Typography>
        </Box>
      );
    }
  // }
  


  console.log(orders)
  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
     
    }}
  >
      {/* <Loading /> */}
     <Paper className="col-md-12 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0" elevation={3}>
     <Table>
      <TableHead>
        <TableRow sx={{
            mt:5
          }}>
          <TableCell>
            <Typography variant="h6">Item</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h6">Qty</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h6">Status</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      
        {orders?.description?.map((item) => (
          <TableRow key={item._id}>
            {item.product.map((product) => (
              <React.Fragment key={product._id}>
                <TableCell
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {product.name}
                </TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {orders.status}
                </TableCell>
              </React.Fragment>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </Paper>
  </Box>
  );
};

export default Qr;




