import React from 'react';
import Router from './Router';
import Headers from './components/Header';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Footer from './components/Footer';
function App() {
  return (
    <>
    <BrowserRouter >
    <ThemeProvider theme={theme}>
    <Headers />
    <Router />
    <Footer />
    </ThemeProvider>
    </BrowserRouter>
    </>
  );
}

export default App;
