import { createTheme } from '@mui/material/styles';
import typography from './typography'; // Correct the path to your typography file

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFCFF', // Customize the primary color
    },
    secondary: {
      main: '#3F51B5', // Customize the secondary color
    },
    gray: {
      backgroundColor: '#F3F7F0',
    },
    macho: {
      main: '#6D213C',
    },
    // Add more customizations to the palette as needed
  },
  transitions: {
    smoothVisible: 'opacity 0.3s ease-in-out', // Customize the transition as needed
  },
  typography, // Apply the imported typography settings to the theme
});

export default theme;
