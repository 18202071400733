import React from "react";

import Price from "../components/Price";
import HouseItems from "../components/HouseItems";

function Catalogue() {
  return <div>  
  <section id="pricing" className="pricing">
  <div className="container" data-aos="fade-up">
    <div className="section-title">
      <h2>Pricing</h2>
      <h3>
        Check our <span>Price Catalogue</span>
      </h3>
      <p>
      Our pricing is thoughtfully designed to offer competitive rates without compromising on the exceptional quality of our services. Whether it's dry cleaning, laundry or ironing, you can trust that our pricing reflects the care and expertise that go into every task.
      </p>
    </div>
    <div className="row">
  
     
      
      <Price />
      <HouseItems />

    </div>
  </div>
</section>
{/* 
  <section id="skills" className="skills">
  <div className="container" data-aos="fade-up">
    <div className="row skills-content">
      <div className="col-lg-6">
        <div className="progress">
          <span className="skill">
            HTML <i className="val">100%</i>
          </span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={100}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
        <div className="progress">
          <span className="skill">
            CSS <i className="val">90%</i>
          </span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={90}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
        <div className="progress">
          <span className="skill">
            JavaScript <i className="val">75%</i>
          </span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={75}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="progress">
          <span className="skill">
            PHP <i className="val">80%</i>
          </span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={80}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
        <div className="progress">
          <span className="skill">
            WordPress/CMS <i className="val">90%</i>
          </span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={90}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
        <div className="progress">
          <span className="skill">
            Photoshop <i className="val">55%</i>
          </span>
          <div className="progress-bar-wrap">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={55}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}
</div>;


}

export default Catalogue;
