import React from "react";
import { Box, Typography, Link, List, ListItemText, ListItem } from "@mui/material";

function Footer() {
  return (
    <>
      <footer id="footer">
        <Box className="footer-newsletter">
          {/* Newsletter Section */}
        </Box>
        <Box className="footer-top">
          <Box className="container">
            <Box className="row">
              <Box className="col-lg-3 col-md-6 footer-contact">
                <Typography variant="h3">
                  Aisha <span>Drycleaners</span>
                </Typography>
                <Typography variant="body1">
                  General Wairungi Street <br />
                  Eastleigh, BBS
                  <br />
                  Nrb, Kenya <br />
                  <br />
                  <strong>Head Office:</strong> +254 728 722 610 <br />
                  <strong>Sales:</strong> +254 720 381 634
                  <br />
                  <br />
                  <strong>Email:</strong>{" "}
                  <a href="mailto:info@aishadrycleaner.com">
                    info@aishadrycleaner.com
                  </a>
                  <br />
                  <strong>Sales:</strong>{" "}
                  <a href="mailto:info@aishadrycleaner.com">
                    sales@aishadrycleaner.com
                  </a>
                  <br />
                </Typography>
              </Box>
              <Box className="col-lg-3 col-md-6 footer-links">
                <Typography variant="h4">Useful Links</Typography>
                <List>
                  <ListItemText>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link href="/">Home</Link>
                  </ListItemText>
                  <ListItemText>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link href="about">About us</Link>
                  </ListItemText>
                  <ListItemText>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link href="services">Services</Link>
                  </ListItemText>
                  <ListItemText>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link href="contacts">Reach Out</Link>
                  </ListItemText>
                  <ListItemText>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link href="terms">Terms of service</Link>
                  </ListItemText>
                  <ListItemText>
                    <i className="bx bx-chevron-right" />{" "}
                    <Link href="private">Privacy policy</Link>
                  </ListItemText>
                </List>
              </Box>
              <Box className="col-lg-3 col-md-6 footer-links">
                <Typography variant="h4">Our Services</Typography>
                <List>
                  <ListItem>
                  <i className="bx bx-chevron-right" />{" "}
                    Dry Cleaning
                    </ListItem>
                  <ListItem>
                  <i className="bx bx-chevron-right" />{" "}
                    Laundry Services</ListItem>
                  <ListItem>
                  <i className="bx bx-chevron-right" />{" "}
                    Express Services</ListItem>
                  <ListItem>
                  <i className="bx bx-chevron-right" />{" "}
                    Stain Removal</ListItem>
                </List>
              </Box>
              <Box className="col-lg-3 col-md-6 footer-contact">
                <Typography variant="h3">
                  Our <span>Branches</span>
                </Typography>
                <Typography variant="body1">
                <strong>Head Office</strong>  <br />
                  Gen Wairungi St BBS Mall <br />
                  <strong>Phone:</strong> +254 728 722 610 <br />
                  <br />
                 
                  <br />
                  <strong>Eastleigh</strong>  <br />
                  6th Street, 2nd Avenue <br />
                  <strong>Phone:</strong> +254 797 455 223 <br />
                  <br />
                 
                
                  <br />
                </Typography>
              </Box>

              <Box className="col-lg-3 col-md-6 footer-links">
            <h4>Our Social Networks</h4>
            <p>
              {/* Cras fermentum odio eu feugiat lide par naso tierra videa magna
              derita valies */}
            </p>
            <Box className="social-links mt-3">
              <a href="/" className="twitter">
                <i className="bx bxl-twitter" />
              </a>
              <a href="/" className="facebook">
                <i className="bx bxl-facebook" />
              </a>
              <a href="/" className="instagram">
                <i className="bx bxl-instagram" />
              </a>
              <a href="/" className="google-plus">
                <i className="bx bxl-skype" />
              </a>
              <a href="/" className="linkedin">
                <i className="bx bxl-linkedin" />
              </a>
            </Box>
          </Box>
            </Box>
          </Box>
        </Box>
        <Box className="container py-4">
          <Typography variant="body2" className="copyright">
            © Copyright{" "}
            <strong>
              <span>Aisha Drycleaners</span>
            </strong>
            . All Rights Reserved
          </Typography>
          <Box className="credits">
            Designed by{" "}
            <Link
              href="https://enigmance.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="./img/copyright.svg"
                width="100px"
                height="51px"
                alt="enigmance"
              />
            </Link>
          </Box>
        </Box>
      </footer>
    </>
  );
}

export default Footer;
