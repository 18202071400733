import React from "react";
import { Container, Typography, Box, Grid } from '@mui/material';

function Service({ services }) {
  return (
    <>
      <Container id="services" className="services ">
        <Box className="container" data-aos="fade-up">
          <Box className="section-title section-bg">
            <h2>Services</h2>
            <h3>
              Check our <span>Services</span>
            </h3>
            <p>
              At Aisha Dry Cleaners, we take pride in offering a comprehensive range of services to cater to all your garment care needs. Let us pamper your clothes and make them shine with our expert care and attention to detail. Your satisfaction is our top priority, and we guarantee you'll be delighted with the results!
            </p>
          </Box>
          <Grid className="row">
            {services.map((service, index) => (
              <Box
                key={index}
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay={100 * (index + 1)}
              >
                <Box className="icon-box">
                  <Box className="icon">
                    <i className={service.icon}></i>
                  </Box>
                  <Typography variant="h5">{service.title}</Typography>
                  <p>{service.description}</p>
                </Box>
              </Box>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Service;
