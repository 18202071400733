import React from "react";
import {  Box, Typography} from '@mui/material';
import Counts from "../components/Counter";
import VideoModal from "../components/VideoModal";

function Home() {
 
  return (
<>
  

  <section id="hero" className="d-flex align-items-center">
    <Box className="container" data-aos="zoom-out" data-aos-delay={100}>
    <h1>Welcome to <span>Aisha DryCleaners</span></h1>
      <h2>Your premier destination for top-notch and reliable dry cleaning services. We take immense
        pride in providing exceptional garment care and ensuring your clothes are returned to you looking as good as new.
        With years of experience and a commitment to customer satisfaction, we have become a trusted name in the industry.</h2>
      <Box className="d-flex">
        <a href="#about" className="btn-get-started scrollto">
          Get Started
        </a>
       <VideoModal />
      </Box>
    </Box>
  </section>
  {/* End Hero */}
  <main id="main">



    <section id="featured-services" className="featured-services">
      <Box className="container" >
        <Box className="row">
        <Box className="section-title">
        <h3>
          What Defines <span>Us</span>
        </h3>
        
      </Box>
          <Box className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <Box className="icon-box" data-aos="fade-up" data-aos-delay={100}>
              <Box className="icon">
                <i className="bx bxl-dribbble" />
              </Box>
              <Typography variant='h4' className="title">
                Unleash the Elegance
              </Typography>
              <p className="description">
              We are not just about cleaning clothes; we are about caring for your clothing and providing
              you with a delightful experience. Our dedication to quality, reliability, and customer satisfaction has earned us
              a loyal clientele that continues to rely on our services year after year.
              </p>
            </Box>
          </Box>
          <Box className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <Box className="icon-box" data-aos="fade-up" data-aos-delay={200}>
              <Box className="icon">
                <i className="bx bx-file" />
              </Box>
              <Typography variant='h4' className="title">
               Where Quality and Care Meet Fashion
              </Typography>
              <p className="description">
                We understand the importance of clean and well-maintained clothes. Our team of skilled professionals employs state-of-the-art technology and eco-friendly cleaning methods to ensure that your garments
                are treated with the utmost care. Whether it's delicate fabrics, intricate designs, or everyday wear, we handle
                each item with precision and attention to detail.
              </p>
            </Box>
          </Box>
          <Box className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <Box className="icon-box" data-aos="fade-up" data-aos-delay={300}>
              <Box className="icon">
                <i className="bx bx-tachometer" />
              </Box>
              <Typography variant='h4' className="title">
                Excellence Redefined
              </Typography>
              <p className="description">
              Our wide range of services includes dry cleaning, laundry, ironing, and garment repairs. We cater to all types of
              clothing, from formal attire and designer outfits to casual wear and household linens. You can trust us to deliver
              impeccable results while extending the longevity of your favorite pieces.
              </p>
            </Box>
          </Box>
          <Box className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <Box className="icon-box" data-aos="fade-up" data-aos-delay={400}>
              <Box className="icon">
                <i className="bx bx-world" />
              </Box>
              <Typography variant='h4' className="title">
                Your Garments' Best Friend
              </Typography>
              <p className="description">
              We prioritize convenience and efficiency for our valued customers. With multiple locations strategically placed
              for easy access, drop-off and pick-up are hassle-free. Our friendly and knowledgeable staff is always ready to
              assist you and address any specific requests or concerns you may have.
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </section>
    
    <section className="section-bg">
    <Box className="container " data-aos="fade-up" data-aos-delay={100}>
        <Box className="row">
        <Box className="section-title">
        <h3>
        Why To Choose <span>Us</span>
        </h3>
        
      </Box>
      
      <Box className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
      <img
              src="img/portfolio/clothes.png"
              className="img-fluid"
              width='100%'
              
              alt=""
            />
      </Box>
      <Box className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
      <p>
      At Aisha Dry Cleaners, we understand the significance of each fabric, each design, and every memory associated with your clothing. From delicate fabrics to intricate couture, our passion for excellence drives us to redefine the art of garment care. Whether it's dry cleaning, laundry, ironing, or garment repairs, we cater to all your clothing needs, delivering results that are nothing short of extraordinary. With dedication to quality, innovative technology, and eco-friendly practices, we redefine excellence in the world of garment care. 
      </p>
      </Box>

        
      </Box>
      </Box>
    </section>

    <section className="section-bg">
    <Box className="container " data-aos="fade-up" data-aos-delay={100}>
        <Box className="row">
        <Box className="section-title">
        <h3>
          What To Expect From <span>Us</span>
        </h3>
        
      </Box>
      
      <Box className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
      <img
                src="img/portfolio/machine.png"
              className="img-fluid"
              width='100%'
              alt=""
            />
      </Box>
      <Box className="col-md-6 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
      <p>
      With a commitment to caring for your clothing and providing a delightful experience, we go beyond traditional dry cleaning services. Our skilled professionals utilize state-of-the-art technology and eco-friendly cleaning methods to handle everything from delicate fabrics to everyday wear with precision and attention to detail. We redefine excellence by offering a wide range of services, including dry cleaning, laundry, ironing, and garment repairs, catering to all types of clothing and household linens. Discover the difference with Aisha Dry Cleaners, where quality and care meet fashion.
      </p>
      </Box>

        
      </Box>
      </Box>
    </section>



   
    <Counts />

    <section id="hero2" className="d-flex align-items-center">
    <Box className="section-title2">
          <h2>Team</h2>
          <h3>
            Our Hardworking <span>Team</span>
          </h3>
          <p >
         When you choose Aisha Dry Cleaners, you're not just selecting a service; you're becoming part of our family. Meet the team that's ready to exceed your expectations and embrace the elegance of garment care like never before.
          </p>
        </Box>
  </section>

    {/* ======= Clients Section ======= */}
    {/* <section id="clients" className="clients section-bg">
      <div className="container" data-aos="zoom-in">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img
              src="img/clients/client-1.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img
              src="img/clients/client-2.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img
              src="img/clients/client-3.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img
              src="img/clients/client-4.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img
              src="img/clients/client-5.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img
              src="img/clients/client-6.png"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </section> */}

{/*     
    <section id="testimonials" className="testimonials">
      
      <div className="container" data-aos="zoom-in">
        <div
          className="testimonials-slider swiper"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="testimonial-item">
                <img
                  src="images/DSC00461.jpg"
                  className="testimonial-img"
                  alt=""
                />
                <h3>Saul Goodman</h3>
                <h4>Ceo &amp; Founder</h4>
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left" />
                  Proin iaculis purus consequat sem cure digni ssim donec
                  porttitora entum suscipit rhoncus. Accusantium quam, ultricies
                  eget id, aliquam eget nibh et. Maecen aliquam, risus at
                  semper.
                  <i className="bx bxs-quote-alt-right quote-icon-right" />
                </p>
              </div>
            </div>
           
            
          
           
          
         
          
          </div>
          <div className="swiper-pagination" />
        </div>
      </div>
    </section> */}
 
    {/* <section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">
        <div className="section-title"> */}
          {/* <h2>Portfolio</h2>
          <h3>
            Check our <span>Portfolio</span>
          </h3>
          <p>
            Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
            adipisci expedita at voluptas atque vitae autem.
          </p> */}
        {/* </div>
        <div className="row" data-aos="fade-up" data-aos-delay={100}> */}
          {/* <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" className="filter-active">
                All
              </li>
              <li data-filter=".filter-app">App</li>
              <li data-filter=".filter-card">Card</li>
              <li data-filter=".filter-web">Web</li>
            </ul>
          </div> */}
        {/* </div>
        <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <img
              src="img/portfolio/portfolio-1.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="portfolio-info">
              <h4>App 1</h4>
              <p>App</p>
              <a
                href="img/portfolio/portfolio-1.jpg"
                data-gallery="portfolioGallery"
                className="portfolio-lightbox preview-link"
                title="App 1"
              >
                <i className="bx bx-plus" />
              </a>
              <a
                href="portfolio-details.html"
                className="details-link"
                title="More Details"
              >
                <i className="bx bx-link" />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <img
              src="img/portfolio/portfolio-2.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
              <a
                href="img/portfolio/portfolio-2.jpg"
                data-gallery="portfolioGallery"
                className="portfolio-lightbox preview-link"
                title="Web 3"
              >
                <i className="bx bx-plus" />
              </a>
              <a
                href="portfolio-details.html"
                className="details-link"
                title="More Details"
              >
                <i className="bx bx-link" />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <img
              src="img/portfolio/portfolio-3.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="portfolio-info">
              <h4>App 2</h4>
              <p>App</p>
              <a
                href="img/portfolio/portfolio-3.jpg"
                data-gallery="portfolioGallery"
                className="portfolio-lightbox preview-link"
                title="App 2"
              >
                <i className="bx bx-plus" />
              </a>
              <a
                href="portfolio-details.html"
                className="details-link"
                title="More Details"
              >
                <i className="bx bx-link" />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 portfolio-item filter-card">
            <img
              src="img/portfolio/portfolio-4.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="portfolio-info">
              <h4>Card 2</h4>
              <p>Card</p>
              <a
                href="img/portfolio/portfolio-4.jpg"
                data-gallery="portfolioGallery"
                className="portfolio-lightbox preview-link"
                title="Card 2"
              >
                <i className="bx bx-plus" />
              </a>
              <a
                href="portfolio-details.html"
                className="details-link"
                title="More Details"
              >
                <i className="bx bx-link" />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <img
              src="img/portfolio/portfolio-5.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="portfolio-info">
              <h4>Web 2</h4>
              <p>Web</p>
              <a
                href="img/portfolio/portfolio-5.jpg"
                data-gallery="portfolioGallery"
                className="portfolio-lightbox preview-link"
                title="Web 2"
              >
                <i className="bx bx-plus" />
              </a>
              <a
                href="portfolio-details.html"
                className="details-link"
                title="More Details"
              >
                <i className="bx bx-link" />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 portfolio-item filter-app">
            <img
              src="img/portfolio/portfolio-6.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="portfolio-info">
              <h4>App 3</h4>
              <p>App</p>
              <a
                href="img/portfolio/portfolio-6.jpg"
                data-gallery="portfolioGallery"
                className="portfolio-lightbox preview-link"
                title="App 3"
              >
                <i className="bx bx-plus" />
              </a>
              <a
                href="portfolio-details.html"
                className="details-link"
                title="More Details"
              >
                <i className="bx bx-link" />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 portfolio-item filter-card">
            <img
              src="img/portfolio/portfolio-7.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="portfolio-info">
              <h4>Card 1</h4>
              <p>Card</p>
              <a
                href="img/portfolio/portfolio-7.jpg"
                data-gallery="portfolioGallery"
                className="portfolio-lightbox preview-link"
                title="Card 1"
              >
                <i className="bx bx-plus" />
              </a>
              <a
                href="portfolio-details.html"
                className="details-link"
                title="More Details"
              >
                <i className="bx bx-link" />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 portfolio-item filter-card">
            <img
              src="img/portfolio/portfolio-8.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="portfolio-info">
              <h4>Card 3</h4>
              <p>Card</p>
              <a
                href="img/portfolio/portfolio-8.jpg"
                data-gallery="portfolioGallery"
                className="portfolio-lightbox preview-link"
                title="Card 3"
              >
                <i className="bx bx-plus" />
              </a>
              <a
                href="portfolio-details.html"
                className="details-link"
                title="More Details"
              >
                <i className="bx bx-link" />
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <img
              src="img/portfolio/portfolio-9.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
              <a
                href="img/portfolio/portfolio-9.jpg"
                data-gallery="portfolioGallery"
                className="portfolio-lightbox preview-link"
                title="Web 3"
              >
                <i className="bx bx-plus" />
              </a>
              <a
                href="portfolio-details.html"
                className="details-link"
                title="More Details"
              >
                <i className="bx bx-link" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    {/* <section id="team" className="team section-bg">
      <Box className="container" data-aos="fade-up">
        <Box className="section-title">
          <h2>Team</h2>
          <h3>
            Our Hardworking <span>Team</span>
          </h3>
          <p >
         When you choose Aisha Dry Cleaners, you're not just selecting a service; you're becoming a part of our family. Meet the team that's ready to exceed your expectations and embrace the elegance of garment care like never before.
          </p>
        </Box>
        <Box className="row">
          <Box
            className="col-lg-3 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <Box className="member">
              <Box className="member-img">
                <img
                  src="images/product1.jpg"
                  className="img-fluid"
                  alt=""
                />
                <Box className="social">
                  <a href="/">
                    <i className="bi bi-twitter" />
                  </a>
                  <a href="/">
                    <i className="bi bi-facebook" />
                  </a>
                  <a href="/">
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="/">
                    <i className="bi bi-linkedin" />
                  </a>
                </Box>
              </Box>
              <Box className="member-info">
              <h4>Name</h4>
                <span>Position</span>
              </Box>
            </Box>
          </Box>
          <Box
            className="col-lg-3 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <Box className="member">
              <Box className="member-img">
              <img
                  src="images/product1.jpg"
                  className="img-fluid"
                  alt="bb"
                />
                <Box className="social">
                  <a href="/">
                    <i className="bi bi-twitter" />
                  </a>
                  <a href="/">
                    <i className="bi bi-facebook" />
                  </a>
                  <a href="/">
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="/">
                    <i className="bi bi-linkedin" />
                  </a>
                </Box>
              </Box>
              <Box className="member-info">
              <h4>Name</h4>
                <span>Position</span>
              </Box>
            </Box>
          </Box>
          <Box
            className="col-lg-3 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            <Box className="member">
              <Box className="member-img">
              <img
                  src="images/product1.jpg"
                  className="img-fluid"
                  alt=""
                />
                <Box className="social">
                  <a href="/">
                    <i className="bi bi-twitter" />
                  </a>
                  <a href="/">
                    <i className="bi bi-facebook" />
                  </a>
                  <a href="/">
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="/">
                    <i className="bi bi-linkedin" />
                  </a>
                </Box>
              </Box>
              <Box className="member-info">
                <h4>Name</h4>
                <span>Position</span>
              </Box>
            </Box>
          </Box>
          <Box
            className="col-lg-3 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <Box className="member">
              <Box className="member-img">
              <img
                  src="images/product1.jpg"
                  className="img-fluid"
                  alt=""
                />
                <Box className="social">
                  <a href="/">
                    <i className="bi bi-twitter" />
                  </a>
                  <a href="/">
                    <i className="bi bi-facebook" />
                  </a>
                  <a href="/">
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="/">
                    <i className="bi bi-linkedin" />
                  </a>
                </Box>
              </Box>
              <div className="member-info">
              <h4>Name</h4>
                <span>Position</span>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </section> */}

  </main>
  
</>

  )
}

export default Home;
