import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material';
import items from './items.json';

const StyledBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'left',
  boxShadow: 'none'
}));

function HouseItems() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const categoryItems = items.find(category => category.category === 'Household Items');

  return (
    <section id="pricing" className="pricing">
      <div className="container" data-aos="fade-up">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledBox elevation={3}>
              <div className="section-title">
                <Typography variant="h3">
                  Household <span>Items</span>
                </Typography>
              </div>
              <TableContainer>
                <Table className="box">
                  <TableHead>
                    <TableRow>
                      <TableCell className="header-cell" style={{ background: '#6D213C', color: 'white' }}>Items</TableCell>
                      <TableCell className="header-cell" style={{ background: '#6D213C', color: 'white' }}>Price</TableCell>
                      <TableCell className="header-cell" style={{ background: '#6D213C', color: 'white' }}>Express</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {categoryItems.items
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>
                            {item.name === 'Carpets' ? (
                              `${item.price}`
                            ) : (
                              <div>
                                <div>Small: {item.price.small}</div>
                                <div>Medium: {item.price.medium}</div>
                                <div>Large: {item.price.large}</div>
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            {item.name === 'Carpets' ? (
                              `${item.expressPrice}`
                            ) : (
                              <div>
                                <div>Small: {item.expressPrice.small}</div>
                                <div>Medium: {item.expressPrice.medium}</div>
                                <div>Large: {item.expressPrice.large}</div>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={categoryItems.items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{
                  borderTop: '1px solid #ddd',
                  paddingTop: '16px',
                  textAlign: 'right',
                  marginBottom: '16px'
                }}
                backIconButtonProps={{
                  style: {
                    color: '#6d213c',
                    marginRight: '8px',
                    marginBottom: '16px'
                  }
                }}
                nextIconButtonProps={{
                  style: {
                    color: '#6d213c',
                    marginLeft: '8px',
                    marginBottom: '16px'
                  }
                }}
                labelRowsPerPage="Items per page:"
                SelectProps={{
                  style: {
                    color: '#6d213c',
                    marginBottom: '16px'
                  }
                }}
              />
            </StyledBox>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default HouseItems;
