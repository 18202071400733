import React from "react";
import Service from "../components/Servicing";

const servicesData = [
  {
    icon: "bx bxs-washer",
    title: "Professional Dry Cleaning",
    description: "Trust us with your most delicate and cherished garments. Our expert dry cleaning process will keep your clothes looking fresh and feeling luxurious.",
  },
  {
    icon: "bx bx-file",
    title: "Laundry Services",
    description: "From everyday wear to linens and bedding, we provide efficient and high-quality laundry services that ensure your clothes are clean and comfortable.",
  },
  {
    icon: "bx bxs-brightness-half",
    title: "Stain Removal",
    description: "Our skilled technicians are masters at tackling even the toughest stains, leaving your garments spotless and free from blemishes.",
  },
  {
    icon: "bx bx-run",
    title: "Express Services",
    description: "For those urgent moments, take advantage of our express services to have your clothes cleaned and ready in no time.",
  },
  {
    icon: "bx bxs-party",
    title: "Wedding Dress Preservation",
    description: "Preserve the memories of your special day with our wedding dress preservation service. We handle your precious gown with care to keep it pristine for years to come.",
  },
  {
    icon: "bx bxs-spray-can",
    title: "Leather and Suede Cleaning",
    description: "Keep your leather and suede garments looking their best with our specialized cleaning and conditioning services.",
  },
  {
    icon: "bx bx-brush",
    title: "Home Carpet Pressure Washing",
    description: "Revive your home carpets with our professional pressure washing service. We'll remove dirt, stains, and grime, leaving your carpets looking fresh and clean.",
  },
  {
    icon: "bx bx-expand", 
    title: "Professional Ironing",
    description: "Let us take care of your ironing needs. Our expert ironing services will make your clothes look perfectly pressed and ready to wear.",
  },
  {
    icon: "bx bxs-user",
    title: "Formal Wear Care",
    description: "For your special occasions, trust us to care for your formal wear and ensure you look your best.",
  },
];




function Services() {
  return (
    <div>
      <Service services={servicesData} />
     
    </div>
  );
}
export default Services;
