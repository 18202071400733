import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';


import items from './items.json';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material';

const StyledBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'left',
  boxShadow: 'none'
}));

function Price() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const categoryItems = items.find(category => category.category === 'Laundry');

  return (
    
  <section id="pricing" className="pricing">
    <div className="container" data-aos="fade-up">
       
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledBox elevation={3}>
            <div className="section-title">
          <Typography variant="h3">
            Price <span>List</span>
          </Typography>
          
        </div>
              <TableContainer >
                <Table className="box" >
              <TableHead>
                <TableRow>
                  <TableCell className="header-cell"  style={{ background: '#6D213C', color: 'white' }}>Item</TableCell>
                  <TableCell className="header-cell" style={{ background: '#6D213C', color: 'white' }}>Laundry</TableCell>
                  <TableCell className="header-cell" style={{ background: '#6D213C', color: 'white' }}>Express</TableCell>
                  <TableCell className="header-cell" style={{ background: '#6D213C', color: 'white'  }}>Pressing / Ironing</TableCell>
                </TableRow>
              </TableHead>
                  <TableBody>
                    {categoryItems.items
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.price.normal}</TableCell>
                          <TableCell>{item.price.express}</TableCell>
                          <TableCell>{item.price.pressing}</TableCell>
                     
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={categoryItems.items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                style={{
                  borderTop: '1px solid #ddd',
                  paddingTop: '16px',
                  textAlign: 'right',
                  marginBottom: '16px'
                }}
                backIconButtonProps={{
                  style: {
                    color: '#6d213c', // Change the color of the back button
                    marginRight: '8px', // Adding right margin
                    marginBottom: '16px'
                  }
                }}
                nextIconButtonProps={{
                  style: {
                    color: '#6d213c', // Change the color of the next button
                    marginLeft: '8px', // Adding left margin
                    marginBottom: '16px'
                  }
                }}
                labelRowsPerPage="Items per page:" // Customize the label
                SelectProps={{
                  style: {
                    color: '#6d213c', // Change the color of the select input
                    marginBottom: '16px'
                  }
                }}
              />
            </StyledBox>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default Price;
