import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', // Set the width of the modal
  maxHeight: '80vh', // Set the maximum height of the modal to 80% of the viewport height
  bgcolor: 'background.paper',
  boxShadow: 24,


};

const videoStyle = {
  width: '100%', // Set the width of the video element to 100%
  height: '100%', // Set the height of the video element to 100%
};

export default function VideoModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} className="glightbox btn-watch-video">
        <i className="bi bi-play-circle"></i>
        <span>Watch Video</span>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <video controls style={videoStyle}>
            <source src="/videos/New1.mp4" type="video/mp4" />
           
          </video>
        </Box>
      </Modal>
    </div>
  );
}
